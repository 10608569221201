// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

#cards-container {
    position: relative;
    margin: 0 auto;
    margin-top: 5rem;
    padding: 0;
    overflow: visible !important;

    &.glide {
        .glide__track {
            overflow: visible !important;

            .controls {
                position: absolute;
                width: 100%;
                top: 30.5%;
                fill: #52ce84;
                stroke: #52ce84;

                .arrow {
                    position: absolute;
                    cursor: pointer;
                    user-select: none;
                    font-weight: 200;
                    scale: 2;

                    &.left {
                        left: 25%;
                        width: 36px;
                        height: 36px;
                        nav-index: auto;
                        transform-origin: center bottom;
                        transform: scaleX(-1);
                    }

                    &.right {
                        right: 25%;
                        width: 36px;
                        height: 36px;
                        nav-index: auto;
                        transform-origin: center bottom;
                    }

                    circle {
                        fill-opacity: 0;
                        transition: all 750ms cubic-bezier(0.23, 1, 0.32, 1);
                    }

                    &:active {
                        circle {
                            transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
                            fill-opacity: 0.5;
                        }
                    }
                }
            }

            .glide__slides {
                overflow: visible !important;

                .glide__slide {
                    .card-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex-basis: 100%;
                        transform: scale(1);
                        transition: all 0.3s;

                        .card-text {
                            padding: 0.5rem;
                            display: block;
                            width: max-content;

                            ul {
                                list-style: none;
                            }

                            .card-title {
                                font-size: 18px;
                                padding: 0;
                                transition: all 0.3s;
                            }

                            .card-desc {
                                padding: 0;
                                padding-top: 0.3rem;
                                font-size: 15px;
                                transition: all 0.3s;
                            }
                        }

                        .card-img {
                            position: relative;
                            margin-top: 1rem;
                            border-radius: 50%;
                            overflow: hidden;
                            transform: scale(1);
                            transition: transform 0.2s, filter 0.3s;

                            img {
                                z-index: -1;
                                display: block;
                                position: relative;
                                margin: 0 auto;
                                height: 100%;
                                width: auto;
                                filter: grayscale(1);
                            }
                        }

                        &.green {
                            .card-img {
                                &::before {
                                    height: 100%;
                                    width: 100%;
                                    content: "";
                                    position: absolute;
                                    inset: 0;
                                    background-color: $ualise-green-bright;
                                    mix-blend-mode: multiply;
                                }
                            }

                            .card-text {
                                .card-title {
                                    color: $ualise-green-bright;
                                }

                                .card-desc {
                                    color: $ualise-green;
                                }
                            }
                        }

                        &.sand {
                            .card-img {
                                &::before {
                                    height: 100%;
                                    width: 100%;
                                    content: "";
                                    position: absolute;
                                    inset: 0;
                                    background-color: $ualise-sand-bright;
                                    mix-blend-mode: multiply;
                                }
                            }

                            .card-text {
                                .card-title {
                                    color: $ualise-sand-bright;
                                }

                                .card-desc {
                                    color: $ualise-sand;
                                }
                            }
                        }

                        &.purple {
                            .card-img {
                                &::before {
                                    height: 100%;
                                    width: 100%;
                                    content: "";
                                    position: absolute;
                                    inset: 0;
                                    background-color: $ualise-purple-bright;
                                    mix-blend-mode: multiply;
                                }
                            }

                            .card-text {
                                .card-title {
                                    color: $ualise-purple-bright;
                                }

                                .card-desc {
                                    color: $ualise-purple;
                                }
                            }
                        }

                        &:not(.selected) {
                            transform: scale(0.8);

                            .card-img {
                                filter: blur(2.5rem);
                                scale: (0.7);
                                transform: translate3d(0, 0, 0);
                            }
                        }

                        &.selected {
                            .card-img {
                                transform: scale(1);
                                filter: blur(0);
                            }

                            .card-text {
                                .card-title {
                                    nav-index: auto;
                                }

                                .card-desc {
                                    nav-index: auto;
                                }
                            }

                            &.green {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-green-light;
                                    }
                                }
                            }

                            &.sand {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-sand-light;
                                    }
                                }
                            }

                            &.purple {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-purple-light;
                                    }
                                }
                            }
                        }
                    }

                    &.glide__slide--active {
                        .card-item {
                            transform: scale(1.3);

                            .card-img {
                                filter: blur(0);
                                scale: 1;
                            }

                            .card-text {
                                .card-title {
                                    nav-index: auto;
                                }

                                .card-desc {
                                    nav-index: auto;
                                }
                            }

                            &.green {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-green-light;
                                    }
                                }
                            }

                            &.sand {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-sand-light;
                                    }
                                }
                            }

                            &.purple {
                                .card-text {
                                    .card-title {
                                        color: $ualise-neutral-light;
                                    }

                                    .card-desc {
                                        color: $ualise-purple-light;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (width >= 0) {
    #cards-container {
        transform: scale(0.7);

        &.glide {
            .glide__track {
                .controls {
                    &.left {
                        left: 10%;
                    }

                    &.right {
                        right: 10%;
                    }
                }

                .glide__slides {
                    .glide__slide {
                        .card-item {
                            &.selected {
                                transform: scale(1.3);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (width >= 40rem) {
    #cards-container {
        transform: scale(1);

        &.glide {
            .glide__track {
                .glide__slides {
                    .glide__slide {
                        .card-item {
                            &.selected {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (width <= 60rem) {
    #cards-container {
        &.glide {
            .glide__track {
                .controls {
                    .arrow {
                        &.left {
                            left: 10% !important;
                        }

                        &.right {
                            right: 10% !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (width <= 40rem) {
    #cards-container {
        &.glide {
            .glide__track {
                .controls {
                    display: none !important;
                }
            }
        }
    }
}

/* Colors that change with scroll */
:root {
    --dark-purple-sand: color-mix(in srgb, #3e2510 0%, #220c38);
    --light-purple-sand: color-mix(in srgb, #fcdcc1 0%, #f7e3ff);
    --bright-purple-sand: color-mix(in srgb, #efa96f 0%, #d87bfb);
    --regular-purple-sand: color-mix(in srgb, #cf762c 0%, #863aa3);
    --dark-green-blue: color-mix(in srgb, #002133 0%, #0f4123);
    --bright-green-blue: color-mix(in srgb, #58bbef 0%, #52ce84);
    --light-green-blue: color-mix(in srgb, #c5ebff 0%, #bcf6d3);
    --regular-green-blue: color-mix(in srgb, #0572ab 0%, #0fa44b);
}

/* Colors */
$ualise-neutral-light: #efefef;
$ualise-neutral-bright: #bcb6c1;
$ualise-neutral: #766f7a;
$ualise-neutral-dark: #29232d;
$ualise-purple-light: #f7e3ff;
$ualise-purple-bright: #d87bfb;
$ualise-purple: #863aa3;
$ualise-purple-dark: #220c38;
$ualise-blue-light: #c5ebff;
$ualise-blue-bright: #58bbef;
$ualise-blue: #0572ab;
$ualise-blue-dark: #002133;
$ualise-sand-light: #fcdcc1;
$ualise-sand-bright: #efa96f;
$ualise-sand: #cf762c;
$ualise-sand-dark: #3e2510;
$ualise-green-light: #bcf6d3;
$ualise-green-bright: #52ce84;
$ualise-green: #0fa44b;
$ualise-green-dark: #0f4123;
$red-dark-1: #bd0000;
$red-dark-2: #8b0000;

/* Text colors */
$text-color: $ualise-neutral-dark;
$text-accent-1: $ualise-green-light;
$text-accent-2: $ualise-green;
$warning-text-color: $red-dark-2;

/* Links */
$link-color: $ualise-green-bright;

/* Animated blobs */
$blob-size: 100px;

/* Purple Blob */
$blob-purple-r: 134;
$blob-purple-g: 58;
$blob-purple-b: 163;
$blob-purple-color: rgb(
    $blob-purple-r,
    $blob-purple-g,
    $blob-purple-b,
    100%
);
$blob-purple-bg-color: rgb(
    $blob-purple-r,
    $blob-purple-g,
    $blob-purple-b,
    0%
);

/* Sand Blob */
$blob-sand-r: 207;
$blob-sand-g: 118;
$blob-sand-b: 44;
$blob-sand-color: rgb($blob-sand-r, $blob-sand-g, $blob-sand-b, 100%);
$blob-sand-bg-color: rgb($blob-sand-r, $blob-sand-g, $blob-sand-b, 0%);

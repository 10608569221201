* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    color: $text-color;
    font-family: new-rubrik, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 1.5em;
    text-size-adjust: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
    max-width: 100%;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
table,
form {
    padding: 1rem;
}

h1 {
    color: $text-accent-1;
    font-weight: 200;
    font-size: 2.7rem;
    line-height: 1.2em;
    padding-left: 0.8rem;
    padding-bottom: 2rem;
}

h2 {
    color: $text-accent-2;
    font-weight: 200;
    font-size: 2.1rem;
    line-height: 1.2em;
    padding-left: 0.9rem;
    padding-bottom: 0.2rem;
}

h3 {
    color: $text-accent-1;
    font-weight: 200;
    font-size: 1.4rem;
    line-height: 1.2em;
    padding-top: 1.6rem;
    padding-bottom: 0.2rem;
}

a {
    color: $link-color;

    &:hover {
        color: $link-color;
        text-decoration: $link-color wavy underline;
    }

    &:active {
        color: $link-color;
        text-decoration: $link-color wavy underline;
    }
}

p {
    padding-top: 0.2rem;
}

table {
    width: 100%;
}

th {
    color: $text-accent-1;
    font-weight: 400;
    padding: 0.5rem;
    padding-left: 1rem;
    text-align: left;
}

td {
    padding: 0;
    padding-left: 1rem;

    &:first-child {
        padding-left: 0;
        padding-right: 0;
    }
}

footer {
    h2 {
        margin-bottom: 0.5rem;
    }

    h3 {
        padding-top: 3.5rem;
    }

    ul {
        padding-top: 0.9rem;
        padding-bottom: 0;
    }

    p {
        padding: 0;
    }

    .footer-last-part {
        padding-top: 4rem;
    }
}

.hidden {
    display: none;
}

.unselectable {
    user-select: none;
}

ul.list-not-list {
    list-style-type: none;
}

.container {
    width: 100%;
}

.content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    width: 100%;
    padding-bottom: 4rem;
}

.breadcrumb {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    max-width: 80rem;
    width: 100%;
    height: 100%;
}

.services-container {
    display: grid;
    gap: 2rem;
    margin-top: 3rem;
    position: relative;

    .service-block {
        overflow-wrap: break-word;
        hyphens: manual;
    }
}

footer .content-container {
    padding-bottom: 7.5rem;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flex-item {
    flex: 0 1 100%;

    ul {
        word-break: break-word;
    }
}

#service-providers-ul ul {
    padding-top: 0;
    padding-left: 3rem;
}

.one-column p {
    max-width: 36rem;
}

.intro {
    p {
        font-size: 1.2em;
        line-height: 1.5em;
        padding-bottom: 1.4rem;
        max-width: none;

        &:last-child {
            padding-bottom: 3rem;
        }
    }
}

.two-columns {
    display: flex;
    flex-direction: column;
}

input[type='submit'] {
    border: 0 none;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.6rem 1rem;
}

/* Clear style for phone number links on PC */ // Note: This shouldn't be here, mayby it could go in responsive; but idk win what file it should go.

@media (pointer: fine) {
    a[href^='tel:'] {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        .bg-purple-dark & {
            color: $ualise-purple-light;
        }

        .bg-sand-dark & {
            color: $ualise-sand-light;
        }

        .bg-sand & {
            color: $ualise-blue-dark;
        }

        .bg-purple-sand & {
            color: var(--light-purple-sand);
        }
    }
}

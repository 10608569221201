.forms-container {
    --duration: 0.5s;
    --circle-size: 7rem;
    --container-padding: 0;
    --padding-closed: 0;
    --padding-open: 1rem;
    --padding-bounce-y-diff: 0.1rem;
    --padding-bounce-x-diff: 0.1rem;
    --padding-bounce-y: calc(
        var(--padding-open) + var(--padding-bounce-y-diff)
    );
    --padding-bounce-x: calc(
        var(--padding-open) + var(--padding-bounce-x-diff)
    );
    --radius-circle: 50%;
    --radius-open: .6rem;
    --button-close-size: 30px;
    --element-left-right-margin: 3px;
    --element-width: calc(100% - (var(--element-left-right-margin) * 2));
    --element-width-responsive: var(--element-width);
    --element-radius: .3rem;
    --initial-text-title: 1.8rem;
    --vertical-spacing-xs: .4rem;
    --vertical-spacing-sm: calc(var(--vertical-spacing-xs) * 2);
    --vertical-spacing-md: calc(var(--vertical-spacing-xs) * 2.8);
    --vertical-spacing-lg: calc(var(--vertical-spacing-xs) * 4.5);
    --input-height: 3rem;

    /* display:flex needed because otherwise the opening btns aren't vertically
     * aligned */
    display: flex;
    padding: 0 var(--container-padding);

    /* Needed for .forms-container so that when a form is open the animations
     * play correctly */
    &.display-block {
        display: block;
    }

    /* || GENERAL STYLES SORTED according to the HTML structure as much as possible */

    .animated-form {
        margin: 0;
        color: $ualise-purple-dark;
        background-color: $ualise-purple-bright;
    }

    button {
        cursor: pointer;
    }

    .btn-open {
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        border-radius: var(--radius-circle);
        background-color: $ualise-green-bright;
        transition: margin 0.15s ease-out, padding 0.15s ease-out,
            background-color 0.3s ease-out;

        &:hover {
            color: inherit;
            margin: -0.5rem 0 0 -0.5rem;
            padding: 0.5rem;
            background-color: $ualise-purple-bright;
            transition: margin 0.4s cubic-bezier(0.2, 3.24, 0.58, 1),
                padding 0.4s cubic-bezier(0.2, 3.24, 0.58, 1),
                background-color 0.5s ease-out;
        }
    }

    .form {
        position: relative;
        width: 100%;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;

        >svg {
            display: block;
            width: var(--button-close-size);
            height: var(--button-close-size);
        }
    }

    .form-content {
        display: block;
        overflow: hidden;
    }

    h3 {
        max-width: calc(100% - var(--button-close-size) - 1rem);
        margin-top: var(--vertical-spacing-md);
        padding: 0;
        font-size: var(--initial-text-title);
        line-height: 1.2em;
        font-weight: 400;
        color: inherit;
    }

    textarea,
    input,
    button {
        /* font props needed as these HTML elements behave differently */
        font-family: inherit;
        font-size: 1rem;
    }

    h3,
    h4,
    p,
    label,
    .iti,
    input,
    textarea,
    form button {
        width: var(--element-width-responsive);

        /* margin-left & right due to onfocus styles in input & textarea
         * (if missing, the left & right border doesn't show on focus) */
        margin-left: var(--element-left-right-margin);
        margin-right: var(--element-left-right-margin);
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-bottom: var(--vertical-spacing-md);
    }

    label {
        display: block;
        margin-bottom: var(--vertical-spacing-xs);
    }

    .iti,
    textarea,
    input {
        border: 1px solid $ualise-purple-dark;
        border-radius: var(--element-radius);
    }

    textarea {
        max-width: var(--element-width-responsive);
        padding: 0.5rem;
        color: inherit;
    }

    input {
        height: var(--input-height);
        padding: 0.2rem 0.5rem 0;
        color: $ualise-purple-bright;
    }

    .iti {
        > * {
            border-radius: var(--element-radius);
        }

        input {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            border: 0;
        }
    }

    .field-error-msg,
    .form-error-msg {
        margin-top: var(--vertical-spacing-xs);
        color: $warning-text-color;
    }

    .field-error {
        border: 4px solid $red-dark-1;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .grecaptcha-branding {
        width: var(--element-width);
        margin-top: var(--vertical-spacing-lg);
        font-size: 0.8rem;
        line-height: 1.4em;
        color: $ualise-purple;

        a {
            color: $ualise-purple;
        }
    }

    form {
        padding: 0;

        > :nth-child(1) {
            margin-top: var(--vertical-spacing-sm);
            margin-bottom: var(--vertical-spacing-lg);

            a {
                color: inherit;
            }
        }

        button {
            width: fit-content;
            padding: 0.8rem 1.2rem 0.7rem;
            color: $ualise-green-bright;
            background-color: $ualise-purple-dark;

            /* 500px to get pill shape */
            border-radius: 500px;
            transition: color 0.4s ease-out,
                background-color 0.4s ease-out;

            &:hover {
                color: $ualise-purple-dark;
                background-color: $ualise-green-bright;
                transition: color 0.2s ease-out,
                    background-color 0.2s ease-out;
            }
        }
    }

    .form-success-msg {
        > h4,
        > p {
            width: var(--element-width);
        }

        > h4 {
            margin-top: var(--vertical-spacing-lg);
            padding: 0;
            font-size: calc(var(--initial-text-title) * 1.7);
            line-height: calc(var(--initial-text-title) * 1.8);
            font-weight: 200;
        }

        > p {
            margin-top: var(--vertical-spacing-xs);
        }
    }

    /* || FORM STATES: closed, opening, open, closing, hidden,
     *                 success-closing, success-opening */

    /* The reason for having opening & closing states instead of an
    * "animating" state is that we might want to add different animations
    * for each. */

    /* There are sets of properties that are DUPLICATED in the code because
     * of the animations initial and final states -> USE Sass TO AVOID IT.
     * Could a Sass mixin maybe do the job? */

    /* Apply changes also to form-opening animation */
    .form-closed {
        width: var(--circle-size);
        height: var(--circle-size);
        margin-right: 1rem;
        padding: var(--padding-closed);
        border-radius: var(--radius-circle);

        .form {
            display: none;
        }
    }

    .form-opening {
        animation: var(--duration) ease-out 0s form-opening;

        @keyframes form-opening {
            /* .form-closed styles (there are differences) */
            0% {
                width: var(--circle-size);
                min-height: var(--circle-size);
                padding: var(--padding-closed);
                border-radius: var(--radius-circle);
            }

            /* Same % than form-content-opening */
            50% {
                width: 100%;
                padding: var(--padding-open);
                border-radius: var(--radius-open);
            }

            70% {
                /* Padding doesn't make width grow more than 100% so the width % has to
                 * be increased */
                width: 102%;
                margin-left: calc(-1 * var(--padding-bounce-x-diff) / 2);
                margin-top: calc(-1 * var(--padding-bounce-y-diff) / 2);
                padding: var(--padding-bounce-y) var(--padding-bounce-x);
            }

            /* .form-open styles */
            100% {
                width: 100%;
                padding: var(--padding-open);
                border-radius: var(--radius-open);
            }
        }

        .btn-open {
            display: none;
        }

        .btn-close {
            animation: var(--duration) ease-out 0s btn-close-opening;
        }

        @keyframes btn-close-opening {
            0%,
            50% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .form-content {
            animation: var(--duration) ease-out 0s form-content-opening;
        }

        @keyframes form-content-opening {
            0% {
                width: 0;
                max-height: 0;
                opacity: 0;
            }

            45% {
                opacity: 0;
            }

            /* Same % than form-opening */
            50% {
                width: 100%;
                opacity: 0.5;
            }

            75% {
                opacity: 1;
            }

            95% {
                max-height: 100vh;
            }

            /* .form-open .form-content styles */
            100% {
                width: 100%;

                /* max-height:none because the user might want to expand the
                 * <textarea> a lot. */
                max-height: none;
                opacity: 1;
            }
        }
    }

    /* Apply changes also to form-opening animation */
    .form-open {
        width: 100%;
        padding: var(--padding-open);
        border-radius: var(--radius-open);

        .btn-open {
            display: none;
        }

        /* Apply changes also to form-content-opening animation */
        .form-content {
            width: 100%;
            max-height: none;
            opacity: 1;
        }
    }

    .form-closing {
        animation: var(--duration) ease-out 0s reverse form-opening,
                   var(--duration) ease-out 0s form-closing-bg-color;

        @keyframes form-closing-bg-color {
            75% {
                background-color: $ualise-purple-bright;
            }

            100% {
                background-color: $ualise-green-bright;
            }
        }

        .btn-open {
            display: none;
        }

        .btn-close {
            animation: var(--duration) ease-out 0s reverse
                btn-close-opening;
        }

        .form-content {
            animation: var(--duration) ease-out 0s reverse
                form-content-opening;
        }
    }

    .form-hidden {
        display: none;
    }

    .form-success-closing {
        animation: var(--duration) ease-out 0s form-success-closing;
    }

    @keyframes form-success-closing {
        0% {
            max-height: 100vh;
            opacity: 1;
        }

        100% {
            max-height: 0;
            opacity: 0;
        }
    }

    .form-success-opening {
        animation: var(--duration) ease-out 0s form-success-opening;
    }

    @keyframes form-success-opening {
        0% {
            max-height: 0;
            padding: 0;
            opacity: 0;
        }

        20% {
            padding: var(--padding-bounce-y-diff) 0;
        }

        50% {
            padding: calc(var(--padding-bounce-y-diff) * 2) 0;
        }

        75% {
            padding: var(--padding-bounce-y-diff) 0;
        }

        100% {
            max-height: 100vh;
            padding: 0;
            opacity: 1;
        }
    }
}

.bg-purple-sand {
    .forms-container {
        .animated-form {
            background-color: var(--bright-purple-sand);
            color: var(--dark-purple-sand);
        }

        .btn-open {
            background-color: var(--bright-green-blue);
            color: var(--dark-purple-sand);
        }

        form {
            > .grecaptcha-branding {
                color: var(--regular-purple-sand);
            }

            a {
                color: var(--dark-purple-sand);
                text-decoration:var(--dark-purple-sand) solid underline;
            }

            > ul {
                input {
                    color: var(--bright-purple-sand);
                }
            }

            > button {
                color: var(--bright-green-blue);
                background-color: var(--dark-purple-sand);
            }
        }

        .form-closing {
            animation: var(--duration) ease-out 0s reverse form-opening,
                       var(--duration) ease-out 0s form-closing-bg-color-sand;

            @keyframes form-closing-bg-color-sand {
                75% {
                    background-color: $ualise-sand-bright;
                }

                100% {
                    background-color: $ualise-blue-bright;
                }
            }
        }
    }
}

.bg-sand {
    .forms-container {
        .animated-form {
            background-color: $ualise-sand-bright;
            color: $ualise-blue-dark;
        }

        .btn-open {
            background-color: $ualise-blue-bright;
            color: $ualise-blue-dark;
        }

        form {
            a {
                color: $ualise-blue-dark;
                text-decoration: $ualise-blue-dark solid underline;
            }

            > .grecaptcha-branding {
                color: $ualise-sand;
            }

            > ul {
                input {
                    color: $ualise-sand-bright;
                }
            }

            > button {
                color: $ualise-blue-light;
                background-color: $ualise-blue;
            }
        }

        .form-closing {
            animation: var(--duration) ease-out 0s reverse form-opening,
                var(--duration) ease-out 0s form-closing-bg-color-sand;

            @keyframes form-closing-bg-color-sand {
                75% {
                    background-color: $ualise-sand-bright;
                }

                100% {
                    background-color: $ualise-blue-bright;
                }
            }
        }
    }
}

.bg-sand-dark {
    .forms-container {
        .animated-form {
            background-color: $ualise-sand-bright;
            color: $ualise-blue-dark;
        }

        .btn-open {
            background-color: $ualise-blue-bright;
            color: $ualise-blue-dark;
        }

        form {
            a {
                color: $ualise-blue-dark;
                text-decoration: $ualise-blue-dark solid underline;
            }

            > .grecaptcha-branding {
                color: $ualise-sand;
            }

            > ul {
                input {
                    color: $ualise-sand-bright;
                }
            }

            > button {
                color: $ualise-blue-light;
                background-color: $ualise-blue;
            }
        }

        .form-closing {
            animation: var(--duration) ease-out 0s reverse form-opening,
                var(--duration) ease-out 0s form-closing-bg-color-sand;

            @keyframes form-closing-bg-color-sand {
                75% {
                    background-color: $ualise-sand-bright;
                }

                100% {
                    background-color: $ualise-blue-bright;
                }
            }
        }
    }
}

.bg-purple-sand .forms-container .btn-open:hover {
    color: var(--light-purple-sand);
    background-color: var(--bright-purple-sand);
}

.bg-sand .forms-container form > button:hover,
.bg-sand-dark .forms-container form > button:hover {
    background-color: $ualise-blue-dark;
}

.bg-purple-sand .forms-container form > button:hover {
    background-color: var(--dark-purple-sand);
}

.bg-sand .forms-container .btn-open:hover,
.bg-sand-dark .forms-container .btn-open:hover {
    color: $ualise-sand-light;
    background-color: $ualise-sand;
}

.bg-purple-sand .forms-container form > .grecaptcha-branding a {
    color: var(--regular-purple-sand);
    text-decoration: var(--regular-purple-sand) solid underline;
}

.bg-sand .forms-container form > .grecaptcha-branding a,
.bg-sand-dark .forms-container form > .grecaptcha-branding a {
    color: $ualise-sand;
    text-decoration: $ualise-sand solid underline;
}

@media only screen and (width >=31rem) {
    .forms-container {
        --container-padding: 1rem;
        --padding-open: 2rem;
        --padding-bounce-y-diff: 0.2rem;
        --padding-bounce-x-diff: 0.2rem;
        --element-width-responsive: 80%;

        h3 {
            margin-top: 0;
        }
    }
}

@media only screen and (width >=74rem) {
    .forms-container {
        --padding-open: 3rem;
        --padding-bounce-y-diff: 0.3rem;
        --padding-bounce-x-diff: 0.3rem;
    }
}

#navigation {
    list-style: none;
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;

    .nav-link {
        a {
            display: inline-block;
            height: 100%;
            position: relative;
            text-decoration: none;
            transition: 800ms cubic-bezier(0.63, 0.27, 0.45, 0.78);
            padding-bottom: 8px;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 3px;
                height: 3px;
                left: 50%;
                transform: translateX(-50%);
                border: solid 1px;
                border-radius: 36px;
                transition: 800ms cubic-bezier(0.44,-0.14,0.74,0.05);
                margin-top: 3px;
            }
        }
    }

    .nav-link.active {
        transition: 100ms cubic-bezier(0.63, 0.27, 0.45, 0.78);

        a {
            color: var(--bright-green-blue);

            &::after {
                border-color: var(--bright-green-blue);
                background-color: var(--bright-green-blue);
                width: 100%;
            }
        }
    }

    .nav-link:not(.active):hover {
        a {
            transition: 300ms cubic-bezier(.18,.89,.6,1.63);

            &::after {
                width: 100%;
                transition: 300ms cubic-bezier(.18,.89,.6,1.63);
            }
        }
    }

    .nav-link:active {
        a {
            color: white !important;
            transition: 75ms cubic-bezier(0.63, 0.27, 0.45, 0.78);

            &::after {
                display: block;
                width: 100%;
                height: 3px;
                margin: auto;
                margin-top: 3px;
                border-radius: 36px;
                transition: 75ms cubic-bezier(0.63, 0.27, 0.45, 0.78);
                background-color: white;
                border-color: white;
            }
        }
    }
}

#language {
    text-transform: uppercase;
    align-self: center;
    display: flex;
    column-gap: 0.5rem;

    span {
        color: $ualise-purple-bright;
    }

    a {
        transition: 250ms cubic-bezier(0.63, 0.27, 0.45, 0.78);

        &:hover {
            color: $ualise-sand-bright;
            text-decoration: $ualise-sand-bright solid underline;
        }
    }
}

#logo {
    padding: 1rem 0.5rem;
    width: calc(2 * 0.5rem + 50px);

    .st0 {
        fill: $ualise-purple;
    }

    .st1 {
        fill: none;
        stroke: $ualise-purple-bright;
        stroke-width: 0.8637;
        stroke-miterlimit: 10;
    }

    .st2 {
        fill: #fff;
    }

    .st3 {
        clip-path: url('#SVGID_00000120517899777817890220000007710845896152758675_');
        fill: none;
        stroke: $ualise-purple-bright;
        stroke-width: 0.8637;
        stroke-miterlimit: 10;
    }
}

.bg-sand-dark {
    #logo {
        .st0 {
            fill: $ualise-sand;
        }

        .st1 {
            stroke: $ualise-sand-bright;
        }

        .st3 {
            stroke: $ualise-sand-bright;
        }
    }
}

.bg-sand {
    #logo {
        .st0 {
            fill: $ualise-sand;
        }

        .st1 {
            stroke: $ualise-sand-bright;
        }

        .st3 {
            stroke: $ualise-sand-bright;
        }
    }

    #navigation {
        .nav-link {
            a {
                color: $ualise-blue;

                &:hover {
                    color: $ualise-sand;
                }
            }
        }

        .nav-link.active {
            a {
                color: $ualise-blue;

                &::after {
                    background-color: $ualise-blue;
                    border-color: $ualise-blue;
                }
            }
        }

        .nav-link:active {
            a {
                color: $ualise-sand-dark !important;

                &::after {
                    background-color: $ualise-sand-dark;
                    border-color: $ualise-sand-dark;
                }
            }
        }
    }
}

.bg-purple-sand {
    #logo {
        .st0 {
            fill: var(--regular-purple-sand);
        }

        .st1 {
            stroke: var(--bright-purple-sand);
        }

        .st3 {
            stroke: var(--bright-purple-sand);
        }
    }

    #navigation {
        .nav-link:not(.active):hover {
            a {
                color: $ualise-purple-light;

                &::after {
                    border-color: $ualise-purple-light;
                }
            }
        }
    }
}

:root {
    --animcolor1a: color-mix(
        in srgb,
        #cf762c 0%,
        #863aa3
    );
    --animcolor1b: color-mix(
        in srgb,
        #cf762c00 0%,
        #863aa300
    );
}

#animated-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -3;
}

#animated-blob-1 {
    border-radius: calc(0.5 * $blob-size);
    position: absolute;
    left: 0;
    top: 0;
    width: $blob-size;
    height: $blob-size;
    z-index: -2;
}

#animated-blob-2 {
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(0.5 * $blob-size);
    height: calc(0.5 * $blob-size);
    z-index: -1;
}

.bg-purple-dark {
    #animated-blob-1 {
        background-image: radial-gradient(
            $blob-purple-color 15%,
            $blob-purple-bg-color 60%
        );
    }

    #animated-blob-2 {
        border: 1px solid $blob-purple-color;
    }
}

.bg-sand-dark {
    #animated-blob-1 {
        background-image: radial-gradient(
            $blob-sand-color 15%,
            $blob-sand-bg-color 60%
        );
    }

    #animated-blob-2 {
        border: 1px solid $blob-sand-color;
    }
}

.bg-purple-sand {
    #animated-blob-1 {
        background-image: radial-gradient(
            var(--animcolor1a) 15%,
            var(--animcolor1b) 60%
        );
    }

    #animated-blob-2 {
        border: 1px solid var(--animcolor1a);
    }    
}

.bg-purple-dark {
    background-color: $ualise-purple-dark;
    color: $ualise-purple-light;

    h1 {
        color: #fff;
    }

    .intro p,
    h2,
    h3,
    .footer-li-title {
        color: $ualise-purple-bright;
    }

    input[type='submit'] {
        background-color: $ualise-green-bright;
        color: $ualise-purple-dark;
    }
}

.bg-sand-dark {
    background-color: $ualise-sand-dark;
    color: $ualise-sand-light;

    h1 {
        color: #fff;
    }

    .intro p,
    h2,
    h3,
    .footer-li-title {
        color: $ualise-sand-bright;
    }

    a {
        color: $ualise-blue-bright;
        text-decoration: $ualise-blue-bright solid underline;

        &:hover {
            color: $ualise-blue-light;
            text-decoration: $ualise-blue-light wavy underline;
        }

        &:active {
            color: $ualise-blue;
            text-decoration: $ualise-blue wavy underline;
        }
    }

    input[type='submit'] {
        background-color: $ualise-green-bright;
        color: $ualise-sand-dark;
    }
}

.bg-sand {
    background-color: $ualise-sand-light;
    color: $ualise-blue-dark;

    h1 {
        color: $ualise-sand;
    }

    .intro p,
    h2,
    h3,
    .footer-li-title {
        color: $ualise-sand;
    }

    a {
        color: $ualise-blue;
        text-decoration: $ualise-blue solid underline;

        &:hover {
            color: $ualise-blue-bright;
            text-decoration: $ualise-blue-bright wavy underline;
        }

        &:active {
            color: $ualise-blue;
            text-decoration: $ualise-blue wavy underline;
        }
    }

    input[type='submit'] {
        background-color: $ualise-green-bright;
        color: $ualise-sand-dark;
    }
}

.bg-purple-sand {
    background-color: var(--dark-purple-sand);
    color: var(--light-purple-sand);

    h1 {
        color: #fff;
    }

    .intro p,
    h2,
    h3,
    .footer-li-title {
        color: var(--bright-purple-sand);
    }

    a {
        color: var(--bright-green-blue);
        text-decoration: var(--bright-green-blue) solid underline;

        &:hover {
            color: var(--light-green-blue);
            text-decoration: var(--light-green-blue) wavy underline;
        }

        &:active {
            color: var(--regular-green-blue);
            text-decoration: var(--regular-green-blue) wavy underline;
        }
    }

    input[type='submit'] {
        background-color: var(--bright-green-blue);
        color: var(--dark-purple-sand);
    }
}

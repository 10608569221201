@media only screen and (width >= 22rem) {
    .breadcrumb {
        padding-left: 2rem;
        padding-right: 1rem;
    }

    .content-container {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }

    #logo {
        width: calc(2 * 0.5rem + 60px);
    }

    .header {
        padding-top: 1.4rem;
    }

    .services-container {
        grid-template-columns: 100%;
        width: 100%;
        left: 0;
    }
}

@media only screen and (width >= 35rem) {
    h1 {
        font-size: 3.2rem;
    }

    .breadcrumb {
        padding-left: 2rem;
        padding-right: 1rem;
    }

    .content-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .flex-container {
        flex-flow: row wrap;
    }

    .flex-item {
        flex: 0 1 50%;
    }

    #logo {
        width: calc(2 * 0.5rem + 70px);
    }

    .header {
        padding-top: 2.1rem;
    }
}

@media only screen and (width >= 40rem) {
    h1 {
        font-size: 3.8rem;
    }

    .content-container {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
    }

    .one-column {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .breadcrumb {
        padding-left: 9rem;
        padding-right: 8rem;
    }

    #logo {
        width: calc(2 * 0.5rem + 80px);
    }

    .header {
        padding-top: 5rem;
    }
}

@media only screen and (width >= 50rem) {
    h2 {
        font-size: 2.8rem;
    }

    h3 {
        font-size: 1.8rem;
    }

    p {
        padding-left: 1.1rem;
        padding-right: 1.1rem;
    }

    .breadcrumb {
        padding-left: 11rem;
        padding-right: 11rem;
    }

    .content-container {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 10rem;
    }

    .one-column {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .two-columns {
        flex-direction: row;
        gap: 2rem;
        max-width: 70rem;
    }

    .two-columns div {
        flex: 1 1 50%;
    }
}

@media only screen and (width >= 70rem) {
    h1 {
        font-size: 4.8rem;
    }

    .breadcrumb {
        padding-left: 17rem;
        padding-right: 16rem;
    }

    .one-column {
        padding-left: 16rem;
        padding-right: 16rem;
    }

    .two-columns {
        flex-direction: row;
        gap: 6rem;
        max-width: 70rem;
    }
}

@media only screen and (width >= 74rem) {
    .flex-item {
        flex: 0 1 calc(100% / 3);
    }

    .services-container {
        grid-template-columns: 50% 50%;
        width: 65rem;
        left: -10rem;
    }
}

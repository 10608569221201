// https://www.npmjs.com/package/intl-tel-input

/* stylelint-disable */
.iti__flag {
    background-image: url("intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("intl-tel-input/build/img/flags@2x.png");
    }
}
/* stylelint-enable */
